import PropTypes from "prop-types";
import React from "react";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import { addOptionsToCloundinaryURL } from "app/utils/image/cloudinaryUtils";

const MAX_WIDTHS = {
	small: 768,
	medium: 978,
	large: 1440,
	xlarge: 1440,
};

/**
 * Version simplifiée du BackgroundProgressiveImg sans la gestion du LazyLoading et de l'effet de transistion du flou vers l'image finale
 * UItilisé dans les carousels du site pour éviter les images floues qui ne se chargent pas
 */
const SimpleBackgroundProgressiveImg = ({
	sizes,
	resolution,
	backgroundPosition,
	src,
	quality,
	cloudinaryOptions,
}) => {
	const cloudinarify = () => {
		let cloudinaryConfig = [];

		const size = sizes && sizes[resolution];

		if (size && size.width) {
			cloudinaryConfig.push(`w_${size.width}`);
		} else {
			cloudinaryConfig.push(`w_${MAX_WIDTHS[resolution]}`);
		}

		if (size && size.height) {
			cloudinaryConfig.push(`h_${size.height}`);
		}

		if (src) {
			return addOptionsToCloundinaryURL(src, cloudinaryConfig.concat(cloudinaryOptions), {
				quality,
			});
		}

		return "";
	};

	const source = cloudinarify();

	const backgroundImageStyle = {
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		height: "100%",
		backgroundImage: `url("${source}")`,
		backgroundPosition: backgroundPosition,
	};

	return (
		resolution !== RESOLUTION.UNKNOWN &&
		sizes[resolution] !== undefined && (
			<div className="simple-background-progressive-image" style={backgroundImageStyle} />
		)
	);
};

SimpleBackgroundProgressiveImg.defaultProps = {
	backgroundPosition: "inherit",
	offset: 1000,
	thumbnail: "",
	sizes: {},
};

SimpleBackgroundProgressiveImg.propTypes = {
	src: PropTypes.string,
	sizes: PropTypes.shape({
		small: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		medium: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		large: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
		xlarge: PropTypes.shape({
			height: PropTypes.number,
			width: PropTypes.number,
		}),
	}),
	resolution: PropTypes.string,
	backgroundPosition: PropTypes.string,
	cloudinaryOptions: PropTypes.arrayOf(PropTypes.string),
	quality: PropTypes.string,
};

export default React.memo(SimpleBackgroundProgressiveImg);
